import React from 'react'
import classnames from 'classnames'
import * as styles from './index.module.less'

interface StandardCardProps {
  className?: string
  item?: any
}
const StandardCard: React.FC<StandardCardProps> = ({
  className,
  item,
}) => {
  return (
    <div
      className={classnames(styles.standardCard, className, 'w-[33.5rem] h-[43.4rem] px-[2.5rem]  lg:w-[360px] lg:h-[472px] lg:px-[38px]')}
      style={{ backgroundImage: `url(${item?.title_bg?.publicURL || item?.title_bg})`, backgroundSize: '100% 100%' }}
    >
      <div className={classnames(styles.header, 'flex items-center w-full h-[9.3rem] mb-[2rem] lg:h-[100px] lg:mb-[24px]')}>
        <img className="inline-block align-middle mr-[1.6rem]" src={item?.title_icon?.publicURL || item?.title_icon} alt={item?.title} />
        <span className="w-max text-[1.8rem] lg:text-[24px] font-medium">{item?.title}</span>
      </div>
      {item?.content_list?.map(el => (
        <div key={el.content_title} className="mb-[0.6rem] lg:mb-[14px]">
          <div className="flex items-center">
            <span className="inline-block w-[0.5rem] h-[0.5rem] bg-[#BECBD8] lg:w-[5px] lg:h-[5px] rounded mr-[1.6rem] align-middle"></span>
            <span className="inline-block w-max text-[1.4rem] leading-[2.4rem] font-medium lg:text-[18px] lg:leading-[24px] text-[#1F2D3D]">{el?.content_title}</span>
          </div>
          {el?.content_text && (<div className="w-max text-[1.4rem] leading-[2.4rem] lg:text-[16px] text-[#475669] lg:leading-[24px] ml-[2.1rem]">{el?.content_text}</div>)} 
          {el?.content_detail_list?.map((it,idx) => (
            <div key={idx} className={classnames('mb-[0.6rem] lg:mb-[2px]', {[styles.contentDetailWrap]: idx === 0})}>
              <div className="flex items-center">
                <span className="inline-block w-[0.5rem] h-[0.5rem] bg-[#BECBD8] lg:w-[5px] lg:h-[5px] rounded mr-[1.6rem] align-middle"></span>
                <span className="inline-block w-max text-[1.4rem] leading-[2.4rem] font-medium lg:text-[18px] lg:leading-[24px] text-[#1F2D3D]">{it?.time}</span>
              </div>
              <div className="w-max text-[1.4rem] leading-[2.4rem] lg:text-[16px] text-[#475669] lg:leading-[24px] ml-[2.1rem]">{it?.info}</div>
            </div>
          ))}
        </div>
      ))}
    </div>
  )
}

export default StandardCard
