import React from 'react'
import classnames from 'classnames'
import StandardCard from './StandardCard'
import SwiperCore, { Scrollbar, A11y, Autoplay, Navigation, Virtual, EffectFade } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import * as styles from './index.module.less'

import 'swiper/swiper-bundle.css'
SwiperCore.use([Scrollbar, A11y, Autoplay, Navigation, Virtual, EffectFade])

interface ServiceStandardsProps {
  className?: string
  dataList?: any[]
}
const ServiceStandards: React.FC<ServiceStandardsProps> = ({
  className,
  dataList,
}) => {
  return (
    <>
      <div className={classnames(styles.serviceStandards, className, 'hidden lg:block')}>
        <div className="flex justify-between lg:mb-[60px] lg:mt-[69px]">
          {dataList?.map((item, index) => (
            <StandardCard item={item} key={item?.title || index} /> 
          ))}
        </div>
      </div>
      <div className={classnames(styles.serviceSwiper,'block lg:hidden px-[2rem] mt-[4rem]')}>
        <Swiper
          direction={'horizontal'} //切换方向
          autoplay={{
            delay: 6000,
          }}
          speed={800}
          effect={'fade'} //切换效果 淡入淡出
          allowTouchMove={true} //允许触摸滑动
          scrollbar={true}
        >
          {dataList?.map((item, index) => {
            return (
              <SwiperSlide key={index}>
                <StandardCard item={item} />
              </SwiperSlide>
            )
          })}
        </Swiper>
      </div>
    </>
  )
}

export default ServiceStandards
