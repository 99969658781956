/*
 * @Author: liyu
 * @Date: 2022-06-15 16:23:32
 * @LastEditTime: 2022-07-13 14:52:14
 * @Description: file content
 * @FilePath: /sensors-www/src/components/Banner/index.tsx
 */
import React, { ReactNode } from 'react'
import classnames from 'classnames'
import Button from 'components/Base/Button'
import { isPc } from 'utils/utils'
import * as styles from './index.module.less'

interface BannerProps {
  className?: string
  bannerImg?: any
  bannerImgMb?: any
  bannerIcon?: any
  bannerIconMb?: any
  title?: string
  titleMb?: string
  btnHref?: string
  btnText?: string
  desc?: string
  btnType?: string // primary
  bannerBackground?: string
  slotTag?: ReactNode
  bannerIconClassName?: string
  titleMbClassName?: string
  bannerIconMbClassName?: string
  descClassName?: string
  bannerIconMbSlot?: ReactNode
  style?: any
  slotButton?: ReactNode
}
const Banner: React.FC<BannerProps> = (props) => {
  const {
    className,
    descClassName,
    bannerImg,
    title,
    desc,
    btnText,
    btnHref,
    btnType = 'primary',
    bannerBackground,
    titleMb,
    bannerImgMb,
    bannerIcon,
    slotTag,
    bannerIconClassName,
    titleMbClassName,
    bannerIconMbClassName,
    style,
    bannerIconMb,
    bannerIconMbSlot,
    slotButton,
  } = props

  const cnames = classnames(styles.Banner, className, 'lg:h-[430px] h-[52rem]')
  return (
    <section
      className={cnames}
      style={{
        backgroundColor: bannerBackground,
        ...style,
      }}
    >
      <div
        className="lg:w-[1200px] mx-auto  h-full relative z-10"
        style={{
          backgroundImage: `url(${bannerImg?.publicURL || bannerImg})`,
          backgroundSize: '100% 100%',
          backgroundRepeat: 'no-repeat',
        }}
      >
        {/* 自定义布局 */}
        {slotTag}
        {/* pc端背景图icon */}
        <div
          className={classnames(
            'hidden lg:block absolute h-[430px] z-[-1] top-0 right-0 overflow-hidden',
            bannerIconClassName,
          )}
        >
          <img className="h-full" src={bannerIcon?.publicURL || bannerIcon} alt="" />
        </div>
        <div className="lg:hidden block absolute h-[52rem] w-full top-0 left-0 z-[-1]">
          <img src={bannerImgMb?.publicURL || bannerImgMb} alt="" />
        </div>
        {/* 移动端背景图icon */}
        {bannerIconMbSlot ||
          (bannerIconMb && (
            <div className="lg:hidden w-full block absolute bottom-[15px] text-center">
              <img src={bannerIconMb} className={classnames(bannerIconMbClassName,'w-[204px] h-[202px]')} />
            </div>
          ))}

        {titleMb && (
          <>
            <div
              className={classnames(titleMbClassName,'text-center text-[2.4rem] leading-[4rem] pt-[4rem] lg:hidden')}
              dangerouslySetInnerHTML={{ __html: titleMb as string }}
            ></div>
          </>
        )}
        <div className="lg:absolute lg:top-1/2 lg:translate-y-[-50%]">
          {title && (
            <>
              <div
                className=" text-[32px] leading-[48px] lg:block hidden"
                dangerouslySetInnerHTML={{ __html: title as string }}
              ></div>
            </>
          )}
          {desc && (
            <p className={classnames(descClassName,'text-center lg:text-left max-w-[588px] pl-[2rem] pr-[2rem] lg:p-[0px] lg:mt-[20px] mt-[1rem] text-[14px] leading-loose lg:leading-[22px] font-light')}>
              {desc}
            </p>
          )}
          {slotButton ? (
            slotButton
          ):(
            btnText && (
              <div className="lg:mt-[60px] mt-[2.5rem] text-center lg:text-left">
                <Button btnType={btnType} href={btnHref} target="_blank">
                  {btnText}
                </Button>
              </div>
            )
          )}
        </div>
      </div>
    </section>
  )
}

export default Banner
