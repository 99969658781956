/*
 * @Author: liyu
 * @Date: 2022-06-16 10:08:30
 * @LastEditTime: 2022-06-17 11:49:19
 * @Description: file content
 * @FilePath: /sensors-www/src/components/IterativeComponent/SectionSubstance/index.tsx
 */
import React from 'react'
import classnames from 'classnames'
import * as styles from './index.module.less'

interface SectionSubstanceProps {
  className?: string
  title?: string
  titleMb?: string
  titleTextAlign?: string
  titleTextAlignMb?: string // left
  descText?: string
  style?: React.CSSProperties
  titleClassName?: string
  titleStyle?: React.CSSProperties
  descTextFlag?: boolean
}
// 标题文本对齐格式
const titleClasses = {
  center: 'section-title-center',
  left: 'section-title-left',
  right: 'section-title-right',
}

const SectionSubstance: React.FC<React.PropsWithChildren<SectionSubstanceProps>> = ({
  children,
  title,
  titleMb,
  descText,
  descTextFlag = true,
  titleTextAlign = 'center',
  titleTextAlignMb,
  className,
  style = {},
  titleClassName,
  titleStyle = {},
}) => {
  return (
    <section style={{ ...style }} className={classnames(styles.SectionContainer, className)}>
      {(titleMb || title) && (
        <h2
          style={titleStyle}
          className={classnames(
            styles.SectionContainerTitle,
            {
              'text-right': titleTextAlign === 'right' && !titleTextAlignMb,
              'text-center': titleTextAlign === 'center' && !titleTextAlignMb,
              'text-left lg:text-center': titleTextAlignMb === 'left',
            },
            titleClassName,
          )}
        >
          <span className="hidden lg:block">{title}</span>
          <span className="block lg:hidden text-[2.2rem] font-semibold">{titleMb}</span>
        </h2>
      )}
      {descText && (
        <p
          className={classnames('section-container-desc', {
            'hidden lg:block': !descTextFlag,
            'text-right': titleTextAlign === 'right' && !titleTextAlignMb,
            'text-center': titleTextAlign === 'center' && !titleTextAlignMb,
            'text-left lg:text-center': titleTextAlignMb === 'left',
          })}
        >
          {descText}
        </p>
      )}
      {children}
    </section>
  )
}

interface SectionSubstanceBodyProps {
  className?: string
}
export const SectionSubstanceBody: React.FC<SectionSubstanceBodyProps> = ({ className, children }) => {
  return <article className={classnames(styles.SectionSubstanceBody, className)}>{children}</article>
}

export default SectionSubstance
