import React, { useState } from 'react';
import classnames from 'classnames'
import * as styles from "./index.module.less";

interface ServiceTabProps {
  className?: string
  tabList?: any[]
  onTabChange:(acticeKey: string) => void
}

const ServiceTab: React.FC<React.PropsWithChildren<ServiceTabProps>> = ({
  className,
  tabList,
  onTabChange,
}) => {
  const [curKey, setCurkey] = useState<string>('ultimate');

  const onChange = (key: string) => {
    setCurkey(key)
    onTabChange(key)
  }

  return  (
    <div className={classnames(styles.serviceTab, className)}>
      <ul className={classnames('w-full h-[5.1rem] flex justify-evenly border-b-2 border-solid border-[#E1E8F1]')}>
        {tabList?.map(item => (
          <li
            key={item.key}
            className={classnames('inline-block w-[8.7rem] h-[5.1rem] leading-[5.1rem] text-center text-[1.8rem] font-medium',{[styles.active]: curKey === item.key})}
            onClick={() => onChange(item.key)}
          >
            {item.name}
          </li>
        ))}
      </ul>
    </div>
  )
}

export default ServiceTab