import React, { useContext, useState, useEffect } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import classnames from 'classnames'
import Seo from 'components/seo'
import Banner from 'components/Banner'
import PageBottomCardNew from 'components/PageBottomCardNew'
import useLoginDialog from 'hooks/useLoginDialog'
import { saTrackSupportLoginClick } from 'utils/statistics'
import ServiceStandards from 'components/Support/ServiceStandards'
import ServiceTable from 'components/Support/ServiceTable'
import ServiceTableMobile from 'components/Support/ServiceTableMobile'
import SectionSubstance from 'components/IterativeComponent/SectionSubstance'
import Layout from 'layouts/zh'
import Button from 'components/Base/Button'
import { providerContext } from 'components/Provider'
import { getUserCertificationNew } from 'api/index'
import { Languages } from 'i18n/config'

import * as styles from './index.module.less'

const Support: React.FC = (props) => {
  const { supportYaml } = useStaticQuery(graphql`
    query {
      supportYaml {
        title
        description
        keywords
        saTitle
        banner_title
        banner_desc
        banner_btnText
        banner_title_m
        banner_bg_mb
        banner_bg_pc
        service_standards_title
        service_standards_list {
          title
          title_bg
          title_icon
          content_list {
            content_title
            content_text
            content_detail_list {
              time
              info
            }
          }
        }
        service_standards_btnText
        service_content_title
        table_list {
          table_title
          fisrt_content
          second_content
          third_content
          four_content
        }
        table_tip
        tab_list {
          key
          name
        }
        ultimate_list {
          title
          content_list
        }
        enterprise_list {
          title
          content_list
        }
        standard_list {
          title
          content_list
        }
        digitization_title
        digitization_desc
        digitization_btnText
        digitization_btnHref
        digitization_orderHref
        digitization_orderText
      }
    }
  `)

  return (
    <Layout {...props}>
      <SupportState data={supportYaml} />
    </Layout>
  )
}

interface SupportStateProps {
  data?: any
  lang?: Languages
}
export const SupportState: React.FC<SupportStateProps> = (props) => {
  const { data } = props
  let { user } = useContext(providerContext)
  const [status, setStatus] = useState<boolean>(false)

  // 获取用户企业认证信息
  const userCertifiedInfo = () => {
    getUserCertificationNew()
      .then((res) => {
        const {
          data: { certifiedStatus },
        } = res as any
        if (certifiedStatus === 1) {
          setStatus(true)
        } else {
          setStatus(false)
        }
      })
      .catch(() => {
        setStatus(false)
      })
  }

  useEffect(() => {
    userCertifiedInfo()
  }, [user])

  // 登录弹窗hook
  const { show: showLoginDialog } = useLoginDialog({
    // 登录成功后回调
    onSuccess: () => {
      saTrackSupportLoginClick(data?.saTitle)
      getUserCertificationNew()
        .then((res) => {
          const {
            data: { certifiedStatus },
          } = res as any
          if (certifiedStatus === 1) {
            setStatus(true)
            window.location.href = 'https://sensorsdata.s4.udesk.cn/im_client/?web_plugin_id=47598&group_id=42302'
          } else {
            setStatus(false)
            window.location.href = 'https://school.sensorsdata.cn/certificate?type=1'
          }
        })
        .catch(() => {
          setStatus(false)
          window.location.href = 'https://school.sensorsdata.cn/certificate?type=1'
        })
    },
  })

  const onClick = () => {
    if (user) {
      // 已登录
      if (!status) {
        // 未认证
        window.location.href = 'https://school.sensorsdata.cn/certificate?type=1'
      } else {
        // 已认证
        window.location.href = 'https://sensorsdata.s4.udesk.cn/im_client/?web_plugin_id=47598&group_id=42302'
      }
    } else {
      // 未登录
      showLoginDialog()
    }
  }

  return (
    <>
      <Seo title={data?.title} description={data?.description} keywords={data?.keywords} saTitle={data?.saTitle} />
      <main className={classnames('pt-[4rem] lg:pt-[64px] relative', styles.Support)}>
        <Banner
          style={{
            background: `url(${data?.banner_bg_pc?.publicURL || data?.banner_bg_pc}) no-repeat`,
            backgroundSize: 'cover',
            color: '#fff',
          }}
          bannerIconClassName={styles.BannerIcon}
          className={styles.Banner}
          descClassName="!max-w-[490px]"
          titleMbClassName="!text-[3rem]"
          bannerIconMbClassName="!w-[354px] !h-[231px]"
          bannerImgMb={data?.banner_bg_mb?.publicURL || data?.banner_bg_mb}
          title={data?.banner_title}
          desc={data?.banner_desc}
          titleMb={data?.banner_title_m}
          bannerIconMb={data?.banner_icon_mb?.publicURL || data?.banner_icon_mb}
          slotButton={
            <div className="lg:mt-[60px] mt-[2.5rem] text-center lg:text-left">
              <Button btnType="primary" onClick={onClick}>
                {data?.banner_btnText}
              </Button>
            </div>
          }
        />
        <SectionSubstance
          className={styles.standardWrap}
          titleClassName={styles.standardTitle}
          title={data?.service_standards_title}
          titleMb={data?.service_standards_title}
        >
          <ServiceStandards dataList={data?.service_standards_list} />
          <div className="lg:mt-[60px] mt-[2rem] text-center">
            <Button btnType="primary" onClick={onClick}>
              {data?.service_standards_btnText}
            </Button>
          </div>
        </SectionSubstance>
        <SectionSubstance
          className={styles.contentWrap}
          titleClassName={styles.contentTitle}
          title={data?.service_content_title}
          titleMb={data?.service_content_title}
        >
          <div className="hidden lg:block mt-[55px]">
            <ServiceTable tableList={data?.table_list} />
          </div>
          <div className="block lg:hidden mt-[2rem]">
            <ServiceTableMobile
              tabList={data?.tab_list}
              ultimateList={data?.ultimate_list}
              enterpriseList={data?.enterprise_list}
              standardList={data?.standard_list}
            />
          </div>
          <div className="lg:mt-[27px] mt-[2rem] mb-[5.8rem] lg:mb-0 text-center">
            <Button btnType="primary" onClick={onClick}>
              {data?.service_standards_btnText}
            </Button>
          </div>
        </SectionSubstance>
        <PageBottomCardNew
          className={styles.pageBottom}
          title={data?.digitization_title}
          desc={data?.digitization_desc}
          leftButtonText={data?.digitization_btnText}
          leftButtonHref={data?.digitization_btnHref}
          rightButtonText={data?.digitization_orderText}
          rightButtonHref={data?.digitization_orderHref}
        />
      </main>
    </>
  )
}

export default Support
