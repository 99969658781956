import React from "react";
import classnames from 'classnames'
import * as styles from './index.module.less'

interface ServiceTableProps {
  className?: string
  tableList?: any[]
}

const ServiceTable: React.FC<ServiceTableProps> = ({
  className,
  tableList
}) => {
  return (
    <div className={classnames(styles.serviceTable, className)}>
      <ul className="mb-[14px] h-[816px] flex">
        {tableList?.map((item, index)=> (
          <li 
            className={classnames(styles.tableLine, 'w-[266px] h-[816px] bg-[#FFFFFF]', {[styles.fisrtLine]: index === 0}, {[styles.secondLine]: index === 1})} 
            key={item?.table_title}
          >
            <div className={classnames('h-[103px] border-b border-solid border-{#E1E8F1} bg-{#F5F6F8} leading-[103px] text-center text-[24px] text-[##1F2D3D] font-medium',{[styles.thirdHead]: index === 2},{[styles.fourHead]: index === 3},{[styles.fiveHead]: index === 4})}>
              {item?.table_title}
            </div>
            <div className="flex justify-center items-center h-[136px] border-b border-solid border-{#E1E8F1}">
              <ul style={index === 1 ? { width: 237 }: {}}>
                {item?.fisrt_content?.map(el => (
                  <li className="text-[14px] text-center text-[#1F2D3D] leading-[30px]" key={el}>{el}</li>
                ))}
              </ul>
            </div>
            <div className="flex justify-center items-center h-[217px] border-b border-solid border-{#E1E8F1}">
              <ul style={index === 1 ? { width: 237 }: {}}>
                {item?.second_content?.map(el => (
                  <li className="text-[14px] text-center text-[#1F2D3D] leading-[30px]" key={el}>{el}</li>
                ))}
              </ul>
            </div>
            <div className="flex justify-center items-center h-[175px] border-b border-solid border-{#E1E8F1}">
              <ul style={index === 1 ? { width: 237 }: {}}>
                {item?.third_content?.map(el => (
                  <li className="text-[14px] text-center text-[#1F2D3D] leading-[30px]" key={el}>{el}</li>
                ))}
              </ul>
            </div>
            <div className="flex justify-center items-center h-[190px]">
              <ul style={index === 1 ? { width: 237 }: {}}>
                {item?.four_content?.map(el => (
                  <li className="text-[14px] text-center text-[#1F2D3D] leading-[30px]" key={el}>{el}</li>
                ))}
              </ul>
            </div>
          </li>
        ))}
      </ul>
      <div className="max-w text-[12px] text-[#475669] leading-[24px]">* 如需购买神策售后相关服务，请联系您的神策客户成功/项目经理进行咨询</div>
    </div>
  )
}

export default ServiceTable;