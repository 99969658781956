import React, { useState } from "react";
import ServiceTab from '../ServiceTab';

interface ServiceTableMobileProps {
  tabList?: any[]
  ultimateList?: any[]
  enterpriseList?: any[]
  standardList?: any[]
}

const ServiceTableMobile: React.FC<ServiceTableMobileProps> = ({
  tabList,
  ultimateList = [],
  enterpriseList = [],
  standardList = [],
}) => {
  const [contentList, setContentList] = useState<any[]>([...ultimateList]);

  const handleTabChange = (key: string) => {
    if(key === 'ultimate') {
      setContentList(ultimateList)
    } else if(key === 'enterprise') {
      setContentList(enterpriseList)
    } else {
      setContentList(standardList)
    }
  }

  return (
    <div>
      <ServiceTab tabList={tabList} onTabChange={(key) => handleTabChange(key)}></ServiceTab>
      <div className="mt-[1.7rem] mx-[2rem]">
        {contentList?.map((item, index) => (
          <div className="mb-[3.3rem]" key={index}>
            <div className="text-[1.4rem] text-[#1F2D3D] leading-[2.4rem] font-medium mb-[2.3rem]">{item.title}</div>
            {item?.content_list?.map((el) => (
              <div className="text-[1.4rem] text-[#1F2D3D] leading-[2.8rem]">{el}</div>
            ))}
          </div>
        ))}
      </div>
    </div>
  )
}

export default ServiceTableMobile; 